import style from './components css/footer.module.css'
import logo from '../assets/image/logo.png';
import { useRef } from 'react';

function Footer(prop) {

    const booleanAnimationRef = useRef([false, false,false,false]);
    prop.referenciaItens.current = [AnimationItens, booleanAnimationRef];

    return(
        <footer className={style.footer}> 
            <div className={style.footer_main}>
            <img className={style.footer_logo} src={logo} alt="logo da empresa" />

            <div className={style.footer_content_item}>
                <h4>Fale Conosco</h4>

                <ul>
                    <li>(11) 96118-3683</li>
                    <li> sidneynascimento@parley.com.br</li>
                    <li>Rua da Consolação, 222 - República, São Paulo - SP,<br></br> 01302-000 SALA 1101 - 11° Andar</li>
                </ul>
            </div>

            <div className={style.footer_content_item}>
                <h4>Horário de atendimento</h4>
                
                <ul>
                    <li className="notranslate">Seg - Sex : 8:00 - 18:00</li>
                    <li>Sabado: Fechado</li>
                    <li>Domingo: Fechado </li>
                </ul>
            </div>
            </div>
        </footer>
    );
}

async function AnimationItens(array){
    
    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop +  window.innerHeight;

    await new Promise(resolve => setTimeout(resolve, 100));

    const logo = document.querySelector(`.${style.footer_logo}`);
    
    if(!array[0] && logo.offsetTop >= docViewTop && docViewBottom >= logo.clientHeight){
        array[0] = adicionadorDeClasse(logo, docViewTop, docViewBottom, 1);
    }

    const content = document.querySelectorAll(`.${style.footer_content_item} h4`);

    if(!array[1]  && content[0].offsetTop >= docViewTop && docViewBottom >= content[0].clientHeight){
        array[1] = adicionadorDeClasse(content[0], docViewTop, docViewBottom, 1);
        adicionadorDeClasse(content[1], docViewTop, docViewBottom, 1);
    }

    if(!array[4]  && content[1].offsetTop >= docViewTop && docViewBottom >= content[0].clientHeight){
        array[4] = adicionadorDeClasse(content[1], docViewTop, docViewBottom, 1);
    }


    const ulList = document.querySelectorAll(`.${style.footer_content_item} ul`);

    if(!array[2] && ulList[0].offsetTop >= docViewTop && docViewBottom >= ulList[0].clientHeight){
        array[2] = await adicionadorDeClasseNosItens(ulList[0], docViewTop, docViewBottom);
    }

    
    if(!array[3] && ulList[1].offsetTop >= docViewTop && docViewBottom >= ulList[1].clientHeight){
        array[3] = await adicionadorDeClasseNosItens(ulList[1], docViewTop, docViewBottom);
    }
    
    return array.every((item)=> item);
}

function adicionadorDeClasse(item, docViewTop, docViewBottom, tipo) {
    if(tipo !== 2 && item.offsetTop >= docViewTop && docViewBottom >= (item.clientHeight + item.offsetTop)){
       
        item.classList.add(`${style.animation_horizontal}`);
        
        return true;
    }else if(tipo === 2){
        item.classList.add(`${style.animation_vertical}`);
        return true;
    }

    return false;
    
}

async function adicionadorDeClasseNosItens(item, docViewTop, docViewBottom) {
    

    if(item.offsetTop >= docViewTop && docViewBottom >= (item.clientHeight / 2 + item.offsetTop)){
    
        const liList = item.querySelectorAll("li");
        
        liList.forEach(async (element) => {
            adicionadorDeClasse(element, docViewTop, docViewBottom, 2);
            await new Promise(resolve => setTimeout(resolve, 200));
        });

        return true;
    }

    return false;
    
}

export default Footer;