import style from './components css/etapas.module.css';
import { CiSearch } from "react-icons/ci";
import { FaRegHandshake } from "react-icons/fa6";
import Circle from './svg/svg_circle';
import Paper from './svg/svg_paper';
import Estatistica from './svg/svg_estatistica';
import Checked from './svg/svg_checked';
import Prancheta from './svg/svg_prancheta';
import { useRef } from 'react';

function Etapas(prop) {

    const booleanAnimationRef = useRef([false, false,false,false, false, false, false, false]);
    prop.referenciaItens.current = [AnimationItens, booleanAnimationRef];

    return(
        <div className={style.etapas} id="etapas">
            <div className={style.etapas_main}>
                <div className={style.etapas_main_text_content}>
                    <h3>Sabemos o que fazer e como fazer!</h3>
                    <h2>Algumas das nossas etapas</h2>
                </div>
        
                <div className={style.etapas_main_content}>
                    <span className={style.etapa_span}></span>
                    <div className={style.etapas_content}>
                        <div className={style.etapas_caixa_1}>
                            <div className={style.etapas_item}>
                                <div className={style.etapas_item_icon}>
                                    <Circle/>
                                    <CiSearch className={style.etapas_item_icon_content}/>
                                </div>
                                <div>
                                    <h4>Pesquisa, Identificação e Análise de Perfil dos Credores</h4>
                                    <p>Realizamos pesquisas abrangentes, identificamos os credores e analisamos seus perfis para entender melhor suas necessidades e interesses.</p>
                                </div>
                            </div>
                            <div className={style.etapas_item}>
                                <div className={style.etapas_item_icon}>
                                    <Circle/>
                                    <FaRegHandshake className={style.etapas_item_icon_content}/>
                                </div>
                                <div>
                                    <h4>Negociação</h4>
                                    <p>Lidamos com as negociações de forma profissional e eficaz, buscando acordos benéficos para todas as partes envolvidas.</p>
                                </div>
                            </div>
                            <div className={style.etapas_item}>
                                <div className={style.etapas_item_icon}>
                                    <Circle/>
                                    <Paper className={style.etapas_item_icon_content} tamanho={"3rem"}/>
                                </div>
                                <div>
                                    <h4>Coleta de Procurações</h4>
                                    <p>Facilitamos a coleta de procurações necessárias para representar os interesses dos credores de maneira legal e adequada.</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.etapas_caixa_2}>
                            <div className={style.etapas_item_invertido}>
                                <div className={style.etapas_item_invertido_text}>
                                    <h4>Monitoramento, Reporte e Feedback</h4>
                                    <p>Mantemos um monitoramento constante, fornecemos relatórios detalhados e solicitamos feedback para garantir transparência e eficiência em todo o processo.</p>
                                </div>
                                <div className={style.etapas_item_icon}>
                                    <Circle/>
                                    <Estatistica className={style.etapas_item_icon_content} tamanho={"3rem"}/>
                                </div>
                            </div>
                            <div className={style.etapas_item_invertido}>
                                <div className={style.etapas_item_invertido_text}>
                                    <h4>Preparação para Assembleia</h4>
                                    <p>Preparamos minuciosamente todos os aspectos necessários para uma assembleia bem-sucedida, garantindo que todos os credores estejam devidamente informados.</p>
                                </div>
                                <div className={style.etapas_item_icon}>
                                    <Circle/>
                                    <Prancheta className={style.etapas_item_icon_content} tamanho={"3rem"}/>
                                </div>
                            </div>
                            <div className={style.etapas_item_invertido}>
                                <div className={style.etapas_item_invertido_text}>
                                    <h4>Pós-Votação</h4>
                                    <p>Continuamos a prestar suporte após a votação, assegurando que as decisões sejam implementadas e que os resultados sejam acompanhados de perto.</p>
                                </div>
                                <div className={style.etapas_item_icon}>
                                    <Circle/>
                                    <Checked className={style.etapas_item_icon_content} tamanho={"3rem"}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

    async function AnimationItens(array){
        const docViewTop = window.scrollY;
        const docViewBottom = docViewTop +  window.innerHeight;
    
    
        const etapasMain = document.querySelector(`.${style.etapas_main_text_content}`);
        
        if(!array[0]){
            const subtitulo = etapasMain.querySelector("h3");
            array[0] = adicionadorDeClasse(subtitulo, docViewTop, docViewBottom);
        }

        if(!array[1]){
            const titulo = etapasMain.querySelector("h2");
            array[1] = adicionadorDeClasse(titulo, docViewTop, docViewBottom);
        }
    


    
    
        const itens = document.querySelectorAll(`.${style.etapas_item}`);

        itens.forEach(async (element, index) => {
            if(!array[index + 2]){
                array[index + 2] = await adicionadorDeClasseNosItens(element, docViewTop, docViewBottom);
            }
        });

        const itensInvertidos = document.querySelectorAll(`.${style.etapas_item_invertido}`);

        itensInvertidos.forEach(async (element, index) => {
            if(!array[index + 5]){
                array[index + 5] = await adicionadorDeClasseNosItens(element, docViewTop, docViewBottom);
            }
        });
        
        return array.every((item)=> item);
    }
    
    function adicionadorDeClasse(item, docViewTop, docViewBottom) {
        if(item.offsetTop >= docViewTop && docViewBottom >= (item.clientHeight + item.offsetTop)){
            item.classList.add(`${style.animation_vertical}`);
            return true;
        }
    
        return false;
        
    }

    async function adicionadorDeClasseNosItens(item, docViewTop, docViewBottom) {
        

        if(item.offsetTop >= docViewTop && docViewBottom >= (item.clientHeight / 2 + item.offsetTop)){
        
            const iconContent = item.querySelector(`.${style.etapas_item_icon}`);
            const titulo = item.querySelector("h4");
            const descricao = item.querySelector("p");

            titulo.classList.add(`${style.animation_vertical}`);

            await new Promise(resolve => setTimeout(resolve, 200));

            descricao.classList.add(`${style.animation_vertical}`);

            await new Promise(resolve => setTimeout(resolve, 200));
            iconContent.classList.add(`${style.animation_vertical}`);

           

            return true;
        }
    
        return false;
        
    }

    
}

export default Etapas;